import {
  ADDMANUAL_PAYIN,
  ADDMANUAL_PAYOUT,
  GET_MERCHANT_PAY_IN_AVAILABLE_PAYMENT,
  GET_MERCHANT_PAY_IN,
  MERCHANT,
  RESET_MERCHANT_ACCOUNT_PASSWORD,
  VIEW_DASHBOARD_LOGS,
  VIEW_MERCHANT_WHITE_LIST_IPS,
  VIEW_STATEMENT,
  GET_MERCHANT_PAY_OUT,
  GET_MERCHANT_PAY_OUT_AVAILABLE_PAYMENT,
  UPDATE_MERCHANT_PAY_OUT_STATUS,
  ADD_MERCHANT_PAY_OUT_META,
  UPDATE_MERCHANT_PAY_IN_LEVEL,
  UPDATE_MERCHANT_PAY_IN_BANK,
  UPDATE_MERCHANT_PAY_IN_STATUS,
  ADD_MERCHANT_PAY_IN_META,
  DELETE_MERCHANT_PAY_IN,
  UPDATE_MERCHANT_DETAILS,
  MERCHANT_ADD,
  UPDATE_BANK_STATUS,
  FETCH_BANK_STATUS,
  DELETE_MERCHANT_PAY_OUT,
  UPDATE_MERCHANT_PAY_IN_MIN_LIMIT,
  UPDATE_MERCHANT_PAY_IN_MAX_LIMIT,
  UPDATE_MERCHANT_PAY_IN_DAILY_LIMIT,
  UPDATE_MERCHANT_PAY_IN_PER_LIMIT,
  MERCHANT_UPDATEPERMISSION,
  GET_MERCHANT_PERMISSION,
  GET_PENDING_SETTLEMENT,
  ADD_MERCHANT_SETTLEMENT,
  UPDATE_MERCHANT_STATUS,
  GET_MERCHANT_MODULE,
  Add_MERCHNAT_WHITELISTIP,
  ADD_MERCHANT_CENTRAL_BANK,
  GET_MERCHANT_CENTRAL_BANK,
  ADD_MERCHANT_PAY_OUT_CENTRAL_BANK,
  ADD_MERCHANT_PAY_IN_CENTRAL_BANK,
  ADD_MERCHANT_PAY_IN_PAY_OUT_CENTRAL_BANK,
  ADD_MERCHANT_TABLE_CENTRAL_BANK,
  UPDATE_PAYOUT_DAILY_LIMIT,
  UPDATE_MERCHANT_PAY_IN_PAYMENT_METHODS,
  UPDATE_MERCHANT_PAY_IN_PAYMENT_LEVEL,
  UPDATE_MERCHANT_WHITE_LISTIPS_STATUS,
  ADD_CENTRAL_BANK_MERCHANT,
  MERCHANT_CHECKOUT,
  MERCHANT_CHECKOUT_UPDATE,
  UPDATE_MERCHANT_MIN_MAX_LIMIT,
  UPDATE_MERCHANT_ISFLOW,
  UPDATE_MERCHANT_MIN_AMOUNT,
  UPDATE_MERCHANT_MAX_AMOUNT,
  UPDATE_MERCHANT_ISDESKTOPENABLE,
  UPDATE_MERCHANT_PAY_OUT_INUSE_STATUS,
  GET_MERCHANT_USER,
  ADD_MERCHANT_USER,
  UPDATE_MERCHANT_USER,
  DELETE_MERCHANT_USER,
  RESET_PASSWORD_MERCHANT_USER,
  GET_MERCHANT_ROLE,
  ADD_MERCHANT_ROLE,
  UPDATE_MERCHANT_ROLE,
  DELETE_MERCHANT_ROLE,
  GET_MERCHANT_PER,
  MERCHANTT_LIST,
  UPDATE_LIVE_SETTLEMENT,
  GET_USER_TRAFFIC,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Merchant_Get = async (payload) => {
  return await apiInstance.post(MERCHANT, payload);
};

export const Merchant_List_Get = async (payload) => {
  return await apiInstance.post(MERCHANTT_LIST, payload);
};

export const MerchantCheckoutGet = (payload) => {
  return apiInstance.post(MERCHANT_CHECKOUT, payload);
};

export const MerchantCheckoutUpdate = (payload) => {
  return apiInstance.post(MERCHANT_CHECKOUT_UPDATE, payload);
};

export const Add_Manual_Payout = (payload) => {
  return apiInstance.post(ADDMANUAL_PAYOUT, payload);
};
export const Add_Manual_Payin = (payload) => {
  return apiInstance.post(ADDMANUAL_PAYIN, payload);
};
export const View_Statement = (payload) => {
  return apiInstance.post(VIEW_STATEMENT, payload);
};

export const View_Dashboard_Logs = (payload) => {
  return apiInstance.post(VIEW_DASHBOARD_LOGS, payload);
};
export const View_Merchant_White_list_Ips = (payload) => {
  return apiInstance.post(VIEW_MERCHANT_WHITE_LIST_IPS, payload);
};
export const Update_Merchant_White_ListIps_Status = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_WHITE_LISTIPS_STATUS, payload);
};
export const Reset_Merchant_Account_Password = (payload) => {
  return apiInstance.post(RESET_MERCHANT_ACCOUNT_PASSWORD, payload);
};
export const Update_Merchant_Details = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_DETAILS, payload);
};
export const Update_Merchant_IsFlow = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_ISFLOW, payload);
};
export const Update_Live_Settlement = (payload) => {
  return apiInstance.post(UPDATE_LIVE_SETTLEMENT, payload);
};
export const Update_Merchant_IsDesktopEnable = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_ISDESKTOPENABLE, payload);
};
export const Merchant_Add = (payload) => {
  return apiInstance.post(MERCHANT_ADD, payload);
};
export const Fetch_Bank_Status = (payload) => {
  return apiInstance.post(FETCH_BANK_STATUS, payload);
};
export const Merchant_UpdatePermission = (payload) => {
  return apiInstance.post(MERCHANT_UPDATEPERMISSION, payload);
};
export const Get_Merchant_Permission = (payload) => {
  return apiInstance.post(GET_MERCHANT_PERMISSION, payload);
};
export const Update_Bank_Status = (payload) => {
  return apiInstance.post(UPDATE_BANK_STATUS, payload);
};
export const Get_Pending_Settlement = (payload) => {
  return apiInstance.post(GET_PENDING_SETTLEMENT, payload);
};
export const Add_Merchant_Settlement = (payload) => {
  return apiInstance.post(ADD_MERCHANT_SETTLEMENT, payload);
};
export const Update_Merchant_Status = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_STATUS, payload);
};
export const Get_Merchant_Module = (payload) => {
  return apiInstance.post(GET_MERCHANT_MODULE, payload);
};
export const Add_Merchant_WhitelistIp = (payload) => {
  return apiInstance.post(Add_MERCHNAT_WHITELISTIP, payload);
};
export const Add_Merchant_Central_Bank = (payload) => {
  return apiInstance.post(ADD_MERCHANT_CENTRAL_BANK, payload);
};
export const Get_Merchant_Central_Bank = (payload) => {
  return apiInstance.post(GET_MERCHANT_CENTRAL_BANK, payload);
};
export const Add_Merchant_Pay_In_Pay_Out_Central_Bank = (payload) => {
  return apiInstance.post(ADD_MERCHANT_PAY_IN_PAY_OUT_CENTRAL_BANK, payload);
};
export const Add_Merchant_Table_Central_Bank = (payload) => {
  return apiInstance.post(ADD_MERCHANT_TABLE_CENTRAL_BANK, payload);
};
export const Update_Payout_Daily_Limit = (payload) => {
  return apiInstance.post(UPDATE_PAYOUT_DAILY_LIMIT, payload);
};
export const Add_Central_Bank_Merchant = (payload) => {
  return apiInstance.post(ADD_CENTRAL_BANK_MERCHANT, payload);
};
export const updateMerchantMinMaxLimit = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_MIN_MAX_LIMIT, payload);
};
export const updatemerchantMinAmount = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_MIN_AMOUNT, payload);
};
export const updatemerchantMaxAmount = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_MAX_AMOUNT, payload);
};

// PAY IN
export const Get_Merchant_Pay_In = (payload) => {
  return apiInstance.post(GET_MERCHANT_PAY_IN, payload);
};

export const Get_Merchant_Pay_In_Available_Payment = (payload) => {
  return apiInstance.post(GET_MERCHANT_PAY_IN_AVAILABLE_PAYMENT, payload);
};

export const Add_Merchant_Pay_In_Meta = (payload) => {
  return apiInstance.post(ADD_MERCHANT_PAY_IN_META, payload);
};

export const Update_Merchant_Pay_In_Level = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_LEVEL, payload);
};

export const Update_Merchant_Pay_In_Bank = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_BANK, payload);
};

export const Update_Merchant_Pay_In_Status = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_STATUS, payload);
};

export const Delete_Merchant_Pay_In = (payload) => {
  return apiInstance.post(DELETE_MERCHANT_PAY_IN, payload);
};
export const Update_Merchant_Pay_In_Min_Limit = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_MIN_LIMIT, payload);
};
export const Update_Merchant_Pay_In_Max_Limit = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_MAX_LIMIT, payload);
};
export const Update_Merchant_Pay_In_Daily_Limit = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_DAILY_LIMIT, payload);
};
export const Update_Merchant_Pay_In_Per_Limit = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_PER_LIMIT, payload);
};
export const Add_Merchant_Pay_In_Central_Bank = (payload) => {
  return apiInstance.post(ADD_MERCHANT_PAY_IN_CENTRAL_BANK, payload);
};
export const Update_Merchant_Pay_In_Payment_Methods = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_PAYMENT_METHODS, payload);
};

export const UpdateMerchantPayInPaymentLevel = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_IN_PAYMENT_LEVEL, payload);
};

// PAY OUT
export const Get_Merchant_Pay_Out = (payload) => {
  return apiInstance.post(GET_MERCHANT_PAY_OUT, payload);
};

export const Get_Merchant_Pay_Out_Available_Payment = (payload) => {
  return apiInstance.post(GET_MERCHANT_PAY_OUT_AVAILABLE_PAYMENT, payload);
};

export const Update_Merchant_Pay_Out_Status = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_OUT_STATUS, payload);
};
export const Update_Merchant_Pay_Out_InUse_Status = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_PAY_OUT_INUSE_STATUS, payload);
};

export const Add_Merchant_Pay_Out_Meta = (payload) => {
  return apiInstance.post(ADD_MERCHANT_PAY_OUT_META, payload);
};

export const Delete_Merchant_Pay_Out = (payload) => {
  return apiInstance.post(DELETE_MERCHANT_PAY_OUT, payload);
};
export const Add_Merchant_Pay_Out_Central_Bank = (payload) => {
  return apiInstance.post(ADD_MERCHANT_PAY_OUT_CENTRAL_BANK, payload);
};

//SUPPORT USER
export const getMerchantUser = (payload) => {
  return apiInstance.post(GET_MERCHANT_USER, payload);
};
export const addMerchantUser = (payload) => {
  return apiInstance.post(ADD_MERCHANT_USER, payload);
};
export const updateMerchantUser = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_USER, payload);
};
export const deleteMerchantUser = (payload) => {
  return apiInstance.post(DELETE_MERCHANT_USER, payload);
};
export const resetPwdMerchantUser = (payload) => {
  return apiInstance.post(RESET_PASSWORD_MERCHANT_USER, payload);
};

//MERCHANT ROLE
export const getMerchantRole = (payload) => {
  return apiInstance.post(GET_MERCHANT_ROLE, payload);
};
export const addMerchantRole = (payload) => {
  return apiInstance.post(ADD_MERCHANT_ROLE, payload);
};
export const updateMerchantRole = (payload) => {
  return apiInstance.post(UPDATE_MERCHANT_ROLE, payload);
};
export const deleteMerchantRole = (payload) => {
  return apiInstance.post(DELETE_MERCHANT_ROLE, payload);
};
export const getMerchantPer = (payload) => {
  return apiInstance.post(GET_MERCHANT_PER, payload);
};
export const getUserTraffic = (payload) => {
  return apiInstance.post(GET_USER_TRAFFIC, payload);
};