import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Form,
} from "reactstrap";
import StatsHorizontal from "../../../components/widgets/stats/StatsHorizontal";
import "./Transaction.scss";
import { useDispatch, useSelector } from "react-redux";

import {
  Filter_Data_Transaction,
  PaymentMetaLabelListGet,
  Summary_Transaction,
  getTransaction,
  setTransactionFilter,
  useTransaction,
  useTransactionFilter,
} from "../../../redux/slices/transactionSlice";
import { Table, TableRow } from "../../../components/Table";
import { getStatusStyle } from "../../../theme";
import {
  DROPDOWN_ALL_VALUE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import Switch from "@mui/material/Switch";
import { useForm } from "react-hook-form";

// Image
import Totalpayout from "../../../assets/images/pages/totalpayout.svg";
import Block from "../../../assets/images/pages/block.svg";
import { IoFilterOutline } from "react-icons/io5";
import TransactionTableDetail from "./TransactionTableDetail";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import GenerateReportModal from "../../../components/Custom/Modals/GenerateReportModal/GenerateReportModal";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledCheckBox from "../../../components/Custom/Forms/Controller/ControlledCheckBox";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import {
  searchDataOptions,
  statusDataOptions,
  stateDataOptions,
  blockUserDataOptions,
  showPageDataOptions,
  MethodDataOptions,
  blockCusDataOptions,
} from "./mock";
import { useAutoRefreshTime, useRole } from "../../../redux/slices/authSlice";
import Invoice from "./Invoice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Transaction = () => {
  const {
    transactionFilterState,
    summaryTransactionState,
    paymentMetaLabelListGetData,
  } = useSelector((state) => state.transaction);
  const { transactionState } = useTransaction();
  const transactionFilter = useTransactionFilter();
  const dispatch = useDispatch();
  const autoRefreshTime = useAutoRefreshTime();
  const roleGenerate = useRole("Generate Report");
  const role = useRole("Deposit");
  const payinResRole = useRole("Payin Req Res Logs");
  const [refresh, setRefresh] = useState(false);
  const [isGenerateReportModal, setIsGenerateReportModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null)

  const [isLoading, setIsLoading] = useState(false);
  const invoiceRef = useRef();

  const [pgAccountOptions, setPgAccountOptions] = useState([]);

  const onCloseGenerateRepostModal = () => {
    setIsGenerateReportModal(!isGenerateReportModal);
  };

  const getclientDataOptions = () => {
    const options = transactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  const preparepgDataOptions = () => {
    const options = transactionFilterState?.pg_data
      ?.slice()
      ?.sort((a, b) => a?.pg_name?.localeCompare(b?.pg_name))
      ?.map((val) => {
        const pgDataOption = { value: val?.pg_id, label: val?.pg_name };

        return pgDataOption;
      });
    return options;
  };

  const getpgAccDataOptions = () => {
    const options = paymentMetaLabelListGetData?.data
      ?.slice()
      ?.sort((a, b) => a?.note?.localeCompare(b?.note))
      ?.map((val) => {
        const pgAccDataOption = { value: val?.acId, label: val?.note };
        return pgAccDataOption;
      });
    return options;
  };

  const prepareProcessByData = () => {
    const options = transactionState?.processBy
      ?.slice()
      ?.sort?.((a, b) => a?.localeCompare(b))
      ?.map?.((val) => {
        const clientDataOption = {
          value: val,
          label: val,
        };
        return clientDataOption;
      });
    return options;
  };

  const initialValues = {
    filter: searchDataOptions?.[0]?.value,
    search: "",
    status: DROPDOWN_ALL_VALUE,
    merchantId: DROPDOWN_ALL_VALUE,
    minAmt: "",
    maxAmt: "",
    custState: DROPDOWN_ALL_VALUE,
    pgName: DROPDOWN_ALL_VALUE,
    bankId: DROPDOWN_ALL_VALUE,
    date: [null, null],
    successDate: [null, null],
    sportsmanDate: [null, null],
    blockedUser: DROPDOWN_ALL_VALUE,
    // isBlockedCust: DROPDOWN_ALL_VALUE,
    showPage: DROPDOWN_ALL_VALUE,
    paymentMethod: DROPDOWN_ALL_VALUE,
    processBy: DROPDOWN_ALL_VALUE,
    lateSuccess: false,
    onlymanualpayin: false,
    onlyTempUtr: false,
    isChargeBack: false,
  };

  const isInitialMount = useRef(true);

  const [filter, setFilter] = useState({ ...TRA_FILTER, isToast: false });
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [filterColumns, setFilterColumns] = useState([]);

  const getBlockingIndicatorForState = (isBlock, state, country, city) => {
    if (isBlock && state && country === "IN") {
      var blockedStateArray = [
        "jharkhand",
        "rajasthan",
        "haryana",
        "uttarakhand",
        "chhattisgarh",
        "bihar",
        "gujarat",
        "maharashtra",
        "karnataka",
      ];
      const lowerState = state.toLowerCase();
      if (blockedStateArray.indexOf(lowerState) > -1) {
        if (lowerState !== "maharashtra") {
          return (
            <div>
              <span className="badge badge-danger" style={{ marginTop: "2px" }}>
                {state}
              </span>
            </div>
          );
        }
      }
    }
    if (country !== "IN") {
      return (
        <div>
          <span className="badge badge-danger" style={{ marginTop: "2px" }}>
            {country}
          </span>
        </div>
      );
    }
    return "";
  };

  const getBlockingIndicatorForDataBlocked = (is_block_by_data) => {
    if (is_block_by_data) {
      return (
        <div>
          <span className="badge badge-danger" style={{ marginTop: "2px" }}>
            Manual Blocked
          </span>
        </div>
      );
    }
    return "";
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isChargeBack: values?.isChargeBack ? 1 : "All",
      ...TRA_FILTER,
      isFilter: true,
      isToast: true,
    });
  };

  const getFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) { }
  };

  const preparePayload = (filter) => {
    const {
      isFilter,
      page_no,
      limit,
      filter: dropdown,
      search,
      date,
      successDate,
      sportsmanDate,
      player_register_date,
      minAmt,
      maxAmt,
      pgName,
      ...rest
    } = filter;
    const pgNamee = transactionFilterState?.pg_data?.find((data) => { return data?.pg_id == pgName })

    const payload = isFilter
      ? {
        filter_data: {
          [dropdown]: search,
          startDate: dateFormatter(date?.[0], "start"),
          endDate: dateFormatter(date?.[1], "end", date?.[0]),
          successStartDate: dateFormatter(successDate?.[0], "start"),
          successEndDate: dateFormatter(
            successDate?.[1],
            "end",
            successDate?.[0]
          ),
          sportsmanStartDate: dateFormatter(sportsmanDate?.[0], "start"),
          sportsmanEndDate: dateFormatter(
            sportsmanDate?.[1],
            "end",
            sportsmanDate?.[0]
          ),
          minAmt: minAmt || 0,
          maxAmt: maxAmt || 0,
          pgName: pgNamee?.pg_name || "All",
          ...rest,
        },
        page_no,
        limit,
      }
      : {
        filter_data: {
          startDate: dateFormatter(new Date(), "start"),
          endDate: dateFormatter(new Date(), "end"),
        },
        page_no,
        limit,
      };

    return payload;
  };

  const onGetTransactions = async (loading = false, isToast = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }

      const payload = preparePayload(filter);

      const [_, res] = await Promise.all([
        dispatch(Summary_Transaction(payload)).unwrap(),
        dispatch(getTransaction(payload)).unwrap(),
      ]);
      if ((filter?.isFilter && isToast) || filter?.isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFilterTransactions();
    const newColumns = columns
      ?.filter((item) => item?.isOpen !== false)
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (isInitialMount.current && transactionFilter) {
      isInitialMount.current = false;
      onRedirectFromBankTransaction();
      return;
    }
    if (role?.includes("deposit-list")) {
      onGetTransactions(true);
    }
  }, [filter]);

  const onRedirectFromBankTransaction = async () => {
    if (transactionFilter) {
      Object.entries(transactionFilter || {})?.forEach(([key, item]) => {
        setValue(key, item);
      });
      const values = getValues();
      setFilter({
        ...filter,
        ...values,
        ...TRA_FILTER,
        ...transactionFilter,
        isFilter: true,
      });
      dispatch(setTransactionFilter(false));
    }
  };

  useEffect(() => {
    if (role?.includes("deposit-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          onGetTransactions(true);
        }
      }, autoRefreshTime);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  const { control, getValues, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const PaymentMetaLabelList = async () => {
    try {
      const pgName = transactionFilterState?.pg_data?.find((data) => { return data?.pg_id == watch("pgName") })
      let payload = {
        pgType: "payin",
        pgName: pgName?.pg_name ? [pgName?.pg_name] : "All",
        pg_id: watch("pgName") || "all",
      };
      await dispatch(PaymentMetaLabelListGet(payload)).unwrap();
    } catch (e) { }
  };
  useEffect(() => {
    setPgAccountOptions(
      preparepgDataOptions()?.map((data) => {
        return data?.value;
      })
    );
  }, [transactionFilterState]);

  useEffect(() => {
    if (watch("pgName")/*  || pgAccountOptions?.length */) {
      PaymentMetaLabelList();
    }
  }, [watch("pgName"), /* pgAccountOptions */]);

  const columns = [
    {
      title: "DATE",
      name: "createdAtIst",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.createdAtIst || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updatedAtIst || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Success : </span>
                  {data?.successAtIst || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Customer ID : </span>
                  {data?.custId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "DEPOSIT DETAILS",
      name: "txnId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.txnId ? (
                <div className="order-singal-div">
                  <p>
                    <span> ID : </span>
                    {payinResRole?.includes?.("payinRequestResponseLog-list") ? <span role="button" className="themecolor " onClick={() => {
                      if (data?.txnId) {
                        window.open(`/payin-req-res-logs/${data?.txnId}`, '_blank');
                      }
                    }}>{data?.txnId || ""}</span> : data?.txnId || ""}
                    {/* {data?.txnId} */}
                  </p>
                </div>
              ) : null}

              {data?.pgType ? (
                <div className="order-singal-div">
                  <p>
                    <span> PG Type : </span>
                    {data?.pgType}
                  </p>
                </div>
              ) : null}

              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span>PG Lable : </span>
                    {data?.pgLabel}
                  </p>
                </div>
              ) : null}
              {data?.pgResMsg ? (
                <div className="order-singal-div">
                  <p>
                    <span>Remark : </span>
                    {data?.pgResMsg}
                  </p>
                </div>
              ) : null}
              {data?.pgName ? (
                <div className="order-singal-div">
                  <p>
                    <span> PG Name : </span>
                    <b>{data?.pgName}</b>
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "ORDER DETAILS",
      name: "order id",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Order ID : </span>
                  {/* {requestResRole?.includes?.("requestResponseLog-list") ? <span role="button" className="themecolor " onClick={() => {
                    if (data?.merchantOrderId) {
                      window.open(`/RequestResponseLogs/${data?.merchantOrderId}`, '_blank');
                    }
                  }}>{data?.merchantOrderId || ""}</span> : data?.merchantOrderId || ""} */}
                  {data?.merchantOrderId || ""}

                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> UTR : </span>
                  {data?.utr || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Method : </span>
                  <b>{data?.paymentMethod || ""}</b>
                </p>
              </div>
              <div className="order-singal-div">
                <p className="gap">
                  <span>State : </span>
                  <b>{`${data?.custState || "-"}`}</b>
                </p>
              </div>
              <div className="order-singal-div">
                <p className="gap">
                  <span>Remark : </span>
                  {data?.remark || "-"}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "CLIENT",
      name: "custName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Name : </span>
                  {data?.merchantDatas?.merchantName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> ID : </span>
                  {data?.merchantId || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>PG ID : </span>
                  {data?.pgOrderId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "paymentAmt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Amount : </span>
                  {`${seperator(data?.paymentAmt)}`}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Pg Fees : </span>
                  {seperator(data?.pgFees) || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Setteled : </span>
                  {`${seperator(data?.payableAmt)}`}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "paymentSts",
      Cell: (data) => {
        const { bgClassName } = getStatusStyle(data?.paymentSts);
        const isBlockedByVpn =
          data?.isBlocked && data?.Purpose === "Transaction Blocked By VPN";
        const isBlockedByCustomer =
          data?.isBlocked &&
          data?.Purpose === "Transaction Blocked By Fraud System";
        return (
          <>
            <div>
              <span className={`${bgClassName}`}>{data?.paymentSts}</span>
            </div>
            {isBlockedByVpn ? (
              <div className="mt-50">
                <span className="red-light"></span>
              </div>
            ) : null}
            {isBlockedByCustomer ? (
              <div className="mt-50">
                <span className="green-light"></span>
              </div>
            ) : null}
            {data?.isRisky && (
              <div className="mt-50">
                <span className="red-light"></span>
              </div>
            )}
            {data?.isBlockedCust ? (
              <div className="mt-25">
                <img
                  src={Block}
                  alt="Block"
                />
              </div>
            ) : null}
            <div className="cell-order">
              {data?.isBlocked && data?.custState && data?.custCountry
                ? getBlockingIndicatorForState(
                  data?.isBlocked,
                  data?.custState,
                  data?.custCountry,
                  data?.custCity
                )
                : ""}
              {getBlockingIndicatorForDataBlocked(data?.is_block_by_data)}
            </div>
          </>
        );
      },
    },
    // {
    //   title: "ACTION",
    //   name: "Action",
    //   Cell: (row) => {
    //     return (
    //       <div>
    //         {role?.includes("deposit-list") ? (
    //           <div className="cell-order">
    //             <Button
    //               onClick={(e) => {
    //                 e.stopPropagation();
    //                 invoiceRef.current.row = row;

    //                 generatePDF();
    //               }}
    //               color="primary"
    //               className={"filterbtn btn-smallsize"}
    //             >
    //               Download Invoice
    //             </Button>
    //           </div>
    //         ) : null}
    //       </div>
    //     );
    //   },
    // },
  ];

  const shareColumns = [
    {
      title: "DATE",
      name: "createdAtIst",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.createdAtIst || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updatedAt || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Success : </span>
                  {data?.successAtIst || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Customer ID : </span>
                  {data?.custId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "DEPOSIT DETAILS",
      name: "txnId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.txnId ? (
                <div className="order-singal-div">
                  <p>
                    <span> ID : </span>
                    {data?.txnId}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "ORDER DETAILS",
      name: "order id",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Order ID : </span>
                  {data?.merchantOrderId || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> UTR : </span>
                  {data?.utr || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "CLIENT",
      name: "custName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Name : </span>
                  {data?.merchantDatas?.merchantName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> ID : </span>
                  {data?.merchantId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "paymentAmt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Amount : </span>
                  {`${seperator(data?.paymentAmt)}`}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "paymentSts",
      Cell: (data) => {
        const { bgClassName } = getStatusStyle(data?.paymentSts);
        const isBlockedByVpn =
          data?.isBlocked && data?.Purpose === "Transaction Blocked By VPN";
        const isBlockedByCustomer =
          data?.isBlocked &&
          data?.Purpose === "Transaction Blocked By Fraud System";
        return (
          <>
            <div>
              <span className={`${bgClassName}`}>{data?.paymentSts}</span>
            </div>
            {isBlockedByVpn ? (
              <div className="mt-50">
                <span className="red-light"></span>
              </div>
            ) : null}
            {isBlockedByCustomer ? (
              <div className="mt-50">
                <span className="green-light"></span>
              </div>
            ) : null}
            {data?.isRisky && (
              <div className="mt-50">
                <span className="red-light"></span>
              </div>
            )}
            <div className="cell-order">
              {data?.isBlocked && data?.custState && data?.custCountry
                ? getBlockingIndicatorForState(
                  data?.isBlocked,
                  data?.custState,
                  data?.custCountry,
                  data?.custCity
                )
                : ""}
              {getBlockingIndicatorForDataBlocked(data?.is_block_by_data)}
            </div>
          </>
        );
      },
    },
  ];

  const generatePDF = () => {
    const input = invoiceRef.current;
    html2canvas(input, {
      scale: 6,
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297, "", "FAST");
      pdf.save(`invoice_${input?.row?.txnId}.pdf`);
      setInvoiceData(null)
      invoiceRef.current.row = null
    });
  };

  if (!role.includes("deposit-list")) {
    return;
  }

  return (
    <>
      {role?.includes("deposit-list") ? (
        <div
          className={`payout-main-div ${isLoading && refresh ? "opacity-25" : ""
            }`}
        >
          <Card>
            <CardHeader className="flex-column align-items-start">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <h4 className="main-title">
                  <b>Deposit</b>
                </h4>

                <div
                  className="merchant-management-card w-100 "
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "10px",
                  }}
                >
                  <div className="mb-xl-0 d-flex align-items-center">
                    <Button
                      type="button"
                      className={`${refresh ? "" : "filterbtn"} gap`}
                      color={refresh ? "primary" : ""}
                      onClick={() => setRefresh(!refresh)}
                      title="Auto refresh"
                    >
                      {refresh ? (
                        <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                      ) : (
                        <i className="fa-solid fa-arrows-rotate"></i>
                      )}
                      Auto Refresh
                    </Button>
                  </div>
                  {roleGenerate.includes("generate-deposit-report") ? (
                    <div className="mb-xl-0  d-flex align-items-center">
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          onCloseGenerateRepostModal();
                        }}
                        title="Generate Report"
                      >
                        Generate Report
                      </Button>
                    </div>
                  ) : null}
                  <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                      id="page-header-user-dropdown"
                      tag="button"
                      title="Column Filter"
                    >
                      <IoFilterOutline
                        value={{ color: "#7367F0" }}
                        fill="#7367F0"
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      className="z-3"
                      style={{
                        whiteSpace: "nowrap",
                        padding: 20,
                        transform: "translate(-20px, 22px)",
                      }}
                    >
                      <li className="z-3 text-dark">
                        <span>
                          <i className="fa-solid fa-filter"></i> Filter Column
                        </span>
                      </li>
                      <div className="border-bottom border-light mt-2">
                        {columns?.map((column) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <p>{column?.title}</p>
                              <Switch
                                checked={filterColumns?.includes(column?.name)}
                                size="small"
                                onChange={(e) => {
                                  const newData = [...filterColumns];
                                  const index = newData?.indexOf(column?.name);
                                  if (index > -1) {
                                    newData.splice(index, 1);
                                  } else {
                                    newData.push(column?.name);
                                  }
                                  setFilterColumns(newData);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Form
                className="transaction-main-div"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="d-flex align-items-end mt-1 button-space flex-wrap">
                  <ControlledDropDown
                    name="filter"
                    label="Filter"
                    isDefaultOption={false}
                    options={searchDataOptions}
                    control={control}
                  />
                  <ControlledInput
                    placeholder="Enter Search Value"
                    name="search"
                    label="Search"
                    control={control}
                  />
                  <ControlledDropDown
                    name="status"
                    label="Status"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={statusDataOptions}
                    control={control}
                  />
                  <ControlledDropDown
                    name="merchantId"
                    label="Client"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={getclientDataOptions()}
                    control={control}
                  />
                  <ControlledDropDown
                    name="custState"
                    label="State"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={stateDataOptions}
                    control={control}
                  />
                  <ControlledDropDown
                    label="PG"
                    name="pgName"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={preparepgDataOptions()}
                    control={control}
                  />
                  <ControlledDropDown
                    label="PG Account"
                    name="bankId"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={getpgAccDataOptions()}
                    control={control}
                  />
                  <ControlledDropDown
                    label="Block User"
                    name="blockedUser"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={blockUserDataOptions}
                    control={control}
                  />
                  <ControlledDropDown
                    label="Show Page"
                    name="showPage"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={showPageDataOptions}
                    control={control}
                  />
                  <ControlledDatePicker
                    name="date"
                    placeholder="Select Date"
                    label="Date"
                    maxDate={new Date()}
                    selectsRange
                    control={control}
                  />
                  <ControlledDatePicker
                    name="successDate"
                    placeholder="Select Date"
                    label="Success Date"
                    selectsRange
                    maxDate={new Date()}
                    control={control}
                  />
                  <ControlledDatePicker
                    name="sportsmanDate"
                    placeholder="Select Date"
                    label="Player Register Date"
                    selectsRange
                    maxDate={new Date()}
                    control={control}
                  />
                  <ControlledDropDown
                    label="Method"
                    name="paymentMethod"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={MethodDataOptions}
                    control={control}
                  />

                  <ControlledInput
                    type="number"
                    name="minAmt"
                    label="Min Amount"
                    placeholder="Enter Min Amount"
                    control={control}
                  />
                  <ControlledInput
                    name="maxAmt"
                    type="number"
                    placeholder="Enter Max Amount"
                    label="Max Amount"
                    control={control}
                  />
                  <ControlledDropDown
                    label="Update By"
                    name="processBy"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={prepareProcessByData()}
                    control={control}
                  />
                  {/*  <ControlledDropDown
                    label="Block Customer"
                    name="isBlockedCust"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={blockCusDataOptions}
                    control={control}
                  /> */}
                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      reset();
                      setFilter({ ...TRA_FILTER });
                    }}
                  >
                    Clear
                  </Button>
                </div>

                <div className="text-start mt-1">
                  <ControlledCheckBox
                    name="onlyTempUtr"
                    label="Show Temp UTR Records Only"
                    control={control}
                  />
                  <ControlledCheckBox
                    name="onlymanualpayin"
                    label="Only Manual Deposit"
                    control={control}
                  />
                  <ControlledCheckBox
                    name="lateSuccess"
                    label="Show Late Success"
                    control={control}
                  />
                  <ControlledCheckBox
                    name="isChargeBack"
                    label="Is Charge Back"
                    control={control}
                  />
                </div>
              </Form>
            </CardBody>
          </Card>
          <div className="merchant-management-card w-100">
            <div style={{ display: "unset" }}>
              <div className="fourbox-detail">
                <div className="w-100">
                  <Card className="dollar-icon-main card-margin">
                    <CardHeader>
                      <StatsHorizontal
                        className="fontstyle greentext"
                        color="primary"
                        image={Totalpayout}
                        statTitle={seperator(
                          summaryTransactionState?.summary?.totalTxn,
                          false
                        )}
                        renderStats={
                          <h5 className="fw-bolder mb-0">Total Txn</h5>
                        }
                      />
                    </CardHeader>
                  </Card>
                </div>
                <div className="w-100">
                  <Card className="dollar-icon-main card-margin">
                    <CardHeader>
                      <StatsHorizontal
                        className="fontstyle greentext"
                        color="primary"
                        image={Totalpayout}
                        statTitle={seperator(
                          summaryTransactionState?.summary?.totalPaymentAmt
                        )}
                        renderStats={
                          <h5 className="fw-bolder mb-0">
                            Total Payment Amount
                          </h5>
                        }
                      />
                    </CardHeader>
                  </Card>
                </div>
                <div className="w-100">
                  <Card className="dollar-icon-main card-margin">
                    <CardHeader>
                      <StatsHorizontal
                        className="fontstyle greentext"
                        color="primary"
                        image={Totalpayout}
                        statTitle={seperator(
                          summaryTransactionState?.summary?.totalPayableAmt
                        )}
                        renderStats={
                          <h5 className="fw-bolder mb-0">
                            Total Payable Amount
                          </h5>
                        }
                      />
                    </CardHeader>
                  </Card>
                </div>
                <div className="w-100">
                  <Card className="dollar-icon-main card-margin">
                    <CardHeader>
                      <StatsHorizontal
                        className="fontstyle greentext"
                        color="primary"
                        image={Totalpayout}
                        statTitle={seperator(
                          summaryTransactionState?.summary?.totalPgFees
                        )}
                        renderStats={
                          <h5 className="fw-bolder mb-0">Total Fees</h5>
                        }
                      />
                    </CardHeader>
                  </Card>
                </div>
              </div>
            </div>
          </div>

          <Card>
            <Table
              columns={columns}
              isLoading={isLoading && !refresh}
              data={transactionState?.data || []}
              isData={!!transactionState?.data?.length}
              filterColumns={filterColumns}
              count={transactionState?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                });
              }}
              paginationOptions={TRA_PAGINATION_OPTIONS}
            >
              {transactionState?.data?.map((item) => {
                return (
                  <TableRow
                    tdClassName="position-relative"
                    columns={columns}
                    shareColumns={shareColumns}
                    item={item}
                    filterColumns={filterColumns}
                    iWantShare={true}
                    CustUI={
                      <div className="d-flex flex-column justify-content-between">
                        {role?.includes("download-invoice") ? <div
                          onClick={(e) => {
                            e.stopPropagation();
                            invoiceRef.current.row = item;
                            setInvoiceData(item)
                            setTimeout(() => { generatePDF(); }, 700)
                          }}
                        >
                          <i className="fa-solid fa-download"></i>
                        </div> : null}
                        <div>
                          {item?.totalCustId ? (
                            <Button className="btn-smallsize1 btn-dangerNohover">
                              {item?.totalCustId}
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    }
                  >
                    <TransactionTableDetail
                      item={item}
                      onGet={() =>
                        setFilter({ ...filter, isFilter: true, isToast: false })
                      }
                    />
                  </TableRow>
                );
              })}
            </Table>
          </Card>
        </div>
      ) : null}
      <GenerateReportModal
        isGenerateReportModal={isGenerateReportModal}
        onCloseGenerateRepostModal={onCloseGenerateRepostModal}
        payload={preparePayload(filter)}
        filter={filter}
      />
      <Invoice refe={invoiceRef} data={invoiceData} />
    </>
  );
};

export default Transaction;
