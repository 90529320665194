import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequestResponseModal from "../../../components/Custom/Modals/RequestResponseModal/RequestResponseModal";
import {
  GetRequestResponseLogs,
  useRequestResponseLogs,
} from "../../../redux/slices/requestResponseLogsSlice";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import { Table, TableRow } from "../../../components/Table";
import { responseToaster } from "../../../helperFunctions";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import {
  DROPDOWN_ALL_LABEL,
  DROPDOWN_ALL_VALUE,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";
import { useParams } from "react-router-dom";

const RequestResponseLogs = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.requestResponseLogs);
  const { id } = useParams();

  const [data, setData] = useState({});
  const getRequestResponseLogsState = useRequestResponseLogs();
  const [isViewsendDataModal, setIsViewsendDataModal] = useState(false);

  const methodOptions = [
    { value: DROPDOWN_ALL_VALUE, label: DROPDOWN_ALL_LABEL },
    { value: "POST", label: "POST" },
    { value: "GET", label: "GET" },
  ];

  const searchDataOptions = [
    { value: "id", label: "ID" },
    { value: "requestUrl", label: "Request Url" },
  ];

  const initialValues = {
    isFilter: false,
    page_no: 1,
    limit: 100,
    method: DROPDOWN_ALL_VALUE,
    filter: searchDataOptions?.[0]?.value,
    search: "",
  };

  const [filter, setFilter] = useState(initialValues);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const role = useRole("Request Response Logs");

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "REQUEST METHOD",
      name: "request_method",
    },
    {
      title: "REQUEST URL",
      name: "request_url",
    },
    {
      title: "RESPONSE HTTP CODE",
      name: "response_http_code ",
      Cell: (data) => {
        return (
          <>
            <span
              className={
                data?.response_http_code > 200
                  ? "badge badge-danger"
                  : "badge badge-success"
              }
            >
              {data?.response_http_code || ""}
            </span>
          </>
        );
      },
    },
    {
      title: "CREATED AT",
      name: "created_at",
    },
    {
      title: "DETAILS",
      name: "success_txn_per",
      Cell: (data) => {
        return (
          <>
            <Button
              color="primary"
              className="btn-smallsize"
              style={{ backgroundColor: "var(--chatlook-darkblue--)" }}
              onClick={() => onCloseModal(data)}
            >
              Details
            </Button>
          </>
        );
      },
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const prepareBanklistOptions = () => {
    // const opttions = merchantPayInAvailablePaymentState?.data?.manual
    //   ?.slice()
    //   ?.sort((a, b) => a?.bankName?.localeCompare(b?.bankName))
    //   ?.map((val) => ({
    //     value: val?.accountId,
    //     label: `${val?.bankName} - ${val?.pgLabel} - ${val?.acNumber}`,
    //   }));
    // return opttions?.length
    //   ? [{ value: "ALL", label: "All" }, ...opttions]
    //   : [{ value: "ALL", label: "All" }];
  };

  const onCloseModal = (data) => {
    setData(data);
    setIsViewsendDataModal(!isViewsendDataModal);
  };

  const Get_Request_Response_Logs = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        search,
        method,
        responseHttpCode,
        filter: dropdown,
      } = filter;
      const payload = isFilter
        ? {
          filter_data: {
            [dropdown]: search,
            method,
            responseHttpCode,
          },
          page_no,
          limit,
        }
        : {
          page_no,
          limit,
        };
      const res = await dispatch(GetRequestResponseLogs(payload)).unwrap();
      if (filter?.isFilter) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("requestResponseLog-list")) {
      Get_Request_Response_Logs();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const prepareHttpCodeOptions = () => {
    const httpCodeOptions =
      getRequestResponseLogsState?.httpCode?.map?.((item) => ({
        value: item?.response_http_code,
        label: item?.response_http_code,
      })) || [];

    const allOptions = [...httpCodeOptions];

    return allOptions || [];
  };

  const { control, handleSubmit, getValues, setValue, reset } = useForm({
    defaultValues: initialValues,
  });
  /*   useEffect(() => {
      if (id) {
        setValue("search", id)
        setValue("filter", "id")
      }
    }, [id]) */

  return (
    <>
      {role.includes("requestResponseLog-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Request Response Logs</b>
                    </h4>
                  </div>

                  <div className="d-flex">
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                        id="page-header-user-dropdown"
                        tag="button"
                      >
                        <IoFilterOutline fill="#7367F0" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="z-3"
                        style={{
                          whiteSpace: "nowrap",
                          padding: 20,
                          transform: "translate(-20px, 22px)",
                        }}
                      >
                        <li className="z-3 text-dark">
                          <span>
                            <i className="fa-solid fa-filter"></i> Filter Column
                          </span>
                        </li>
                        <div className="border-bottom border-light mt-2">
                          {columns?.map((column) => {
                            return (
                              <div className="d-flex justify-content-between">
                                <p>{column?.title}</p>
                                <Switch
                                  checked={filterColumns?.includes(
                                    column?.name
                                  )}
                                  size="small"
                                  onChange={(e) => {
                                    const newData = [...filterColumns];
                                    const index = newData?.indexOf(
                                      column?.name
                                    );
                                    if (index > -1) {
                                      newData.splice(index, 1);
                                    } else {
                                      newData.push(column?.name);
                                    }
                                    setFilterColumns(newData);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="d-flex my-xl-auto right-content align-items-end button-space"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ControlledDropDown
                    id="filter"
                    name="filter"
                    label="Filter"
                    isDefaultOption={false}
                    options={searchDataOptions}
                    control={control}
                    // isDisabled={!!id}
                  />
                  <ControlledInput
                    placeholder="Enter Search Value"
                    name="search"
                    label="Search"
                    // disabled={!!id}
                    control={control}
                  />
                  <ControlledDropDown
                    name="method"
                    label="Method"
                    isDefaultOption={false}
                    options={methodOptions}
                    control={control}
                  />
                  <ControlledDropDown
                    name="responseHttpCode"
                    label="Response Http Code"
                    isLoading={isLoading && !filter?.isFilter}
                    isDisabled={isLoading && !filter?.isFilter}
                    isDefaultOption={false}
                    options={prepareHttpCodeOptions()}
                    control={control}
                  />

                  <Button type="submit" color="primary">
                    Apply
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => {
                      reset();
                      setFilter({ ...initialValues });
                    }}
                  >
                    Clear
                  </Button>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <Table
                columns={columns}
                isLoading={isLoading}
                data={getRequestResponseLogsState?.data || []}
                isData={!!getRequestResponseLogsState?.data?.length}
                filterColumns={filterColumns}
                isExpandable={false}
                paginationOptions={TRA_PAGINATION_OPTIONS}
                pagination={filter}
                count={getRequestResponseLogsState?.total_item || 0}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
              >
                {getRequestResponseLogsState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      item={item}
                      filterColumns={filterColumns}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
            <RequestResponseModal
              data={data}
              isViewsendDataModal={isViewsendDataModal}
              onCloseModal={() => onCloseModal()}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default RequestResponseLogs;
