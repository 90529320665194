import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { Table, TableRow } from "../../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../../constant";
import { useDispatch } from "react-redux";
import { dateFormatter, getCurrentMonthDates, responseToaster } from "../../../../helperFunctions";
import {
    getMarchantUserTraffic,
    useUserTraffic,
} from "../../../../redux/slices/merchantSlice";
import { useForm } from "react-hook-form";
import ControlledDatePicker from "../../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { userTrafficIds } from "../mock";

const UserTraffic = (props) => {
    const { merchantData } = props;

    const { startOfMonth, endOfMonth } = getCurrentMonthDates();
    const [filterColumns, setFilterColumns] = useState([]);
    const initialValues = {
        ...FILTER,
        date: [startOfMonth, endOfMonth],
    };
    const [filter, setFilter] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(false);

    const data = useUserTraffic();
    const dispatch = useDispatch();

    const { control, getValues, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
    });

    const columns = [
        {
            title: "Main Domain",
            name: "main_domain",
        },
        {
            title: "Second Domain",
            name: "second_domain",
        },
        {
            title: "count",
            name: "count",
        }
    ];
    
    const onGetData = async () => {
        try {
            setIsLoading(true);
            const { isFilter, date } = filter;
            const clonedPayload = {
                startDate: dateFormatter(date?.[0] || startOfMonth, "start"),
                endDate: dateFormatter(
                    date?.[1] || endOfMonth,
                    "end",
                    date?.[0]
                ),
            }
            const res = await dispatch(getMarchantUserTraffic(clonedPayload)).unwrap();
            if (isFilter) {
                responseToaster(res);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        onGetData();
    }, [filter]);

    useEffect(() => {
        const newColumns = columns?.map((item) => item?.name);
        setFilterColumns(newColumns);
    }, []);

    const onSubmit = async () => {
        const values = getValues();
        setFilter({
            ...filter,
            ...values,
            isFilter: true,
        });
    };

    if (!userTrafficIds?.includes?.(merchantData?.merchantId)) {
        return
    }

    return (
        <>
            <div className="merchant-main-div">
                <Card>
                    <CardHeader className="flex-column align-items-start">
                        <div className="d-flex align-items-center w-100 justify-content-between">
                            <h4 className="main-title">
                                <b>User Traffic</b>
                            </h4>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Form
                            className="d-flex my-xl-auto right-content align-items-start button-space"
                            style={{ flexWrap: "wrap" }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <ControlledDatePicker
                                name="date"
                                maxDate={new Date()}
                                placeholder="Select Date"
                                label="Date"
                                selectsRange
                                control={control}
                            />
                            <div className="d-flex button-space mt-2">
                                <Button type="submit" color="primary">
                                    Apply
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    onClick={() => {
                                        setFilter({ ...initialValues });
                                        reset();
                                    }}
                                >
                                    Clear
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                    <Table
                        columns={columns}
                        filterColumns={filterColumns}
                        isLoading={isLoading}
                        data={data?.data}
                        isData={!!data?.data?.length}
                        count={data?.total_item || 0}
                        isExpandable={false}
                        onColumnsChange={(columns) => {
                            setFilterColumns(columns);
                        }}
                    >
                        {data?.data?.map?.((val) => {
                            return (
                                <TableRow
                                    columns={columns}
                                    filterColumns={filterColumns}
                                    isExpandable={false}
                                    item={val}
                                />
                            );
                        })}
                    </Table>
                </Card>
            </div>

        </>
    );
};

export default UserTraffic;
