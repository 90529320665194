import {
  DOWNLOAD_BATCH_FILE,
  GETCUSTOMERLEVELDATA,
  MANUAL_PAYOUT,
  MANUAL_PAYOUT_BANKLIST,
  MARK_AS_USED_MANUAL,
  RECONCILIATIONPAYMENT,
  RECONCILIATIONPAYMENTACTION,
  REFUND,
  ACCOUNT_BANK_LOAD,
  GENERATE_FILE,
  PAYOUT_AMOUNT_TOTAL,
  UPLOAD_STATUS_FILE,
  BANK_TICKET_SUMMMARY,
  MERCHANT_LIST,
  FED_TRANSCATION,
  TICKET_SUMMMARY,
  UPDATE_IS_MANUAL_LEVEL,
  GET_PAYMENT_BANKLIMIT,
  GET_PAYOUT_BANKLIMIT,
  GET_PAYMENT_BANKLIMIT_DATA,
  UPDATE_PAYMENT_BANKLIMIT,
  UPDATE_PAYOUT_BANKLIMIT,
  UPDATE_FED_TRANSCATION,
  PAYOUT_AMOUNT_TOTAL_MERCHANT,
  ACTIVE_MERCHNAT_PG,
  PAYOUT_TICKET_SUMMMARY,
  BANK_TURNOVER,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getCustomerLevelData = (payload) => {
  return apiInstance.post(GETCUSTOMERLEVELDATA, payload);
};

export const get_Refund = (payload) => {
  return apiInstance.post(REFUND, payload);
};

export const Reconciliation_Payment = (payload) => {
  return apiInstance.post(RECONCILIATIONPAYMENT, payload);
};
export const Reconciliation_Payment_Action = (payload) => {
  return apiInstance.post(RECONCILIATIONPAYMENTACTION, payload);
};
export const Manual_Payout = (payload) => {
  return apiInstance.post(MANUAL_PAYOUT, payload);
};
export const Manual_Payout_Banklist = (payload) => {
  return apiInstance.post(MANUAL_PAYOUT_BANKLIST, payload);
};
export const Download_Batch_File = (payload) => {
  return apiInstance.post(`${DOWNLOAD_BATCH_FILE}/${payload}`);
};
export const mark_as_used_Manual = (payload) => {
  return apiInstance.post(MARK_AS_USED_MANUAL, payload);
};
export const account_bank_load = (payload) => {
  return apiInstance.post(ACCOUNT_BANK_LOAD, payload);
};
export const generate_file = (payload) => {
  return apiInstance.post(GENERATE_FILE, payload);
};
export const payout_amount_total = () => {
  return apiInstance.post(PAYOUT_AMOUNT_TOTAL);
};
export const payout_amount_total_amount = (payload) => {
  return apiInstance.post(PAYOUT_AMOUNT_TOTAL_MERCHANT,payload);
};
export const upload_status_file = (payload) => {
  return apiInstance.post(UPLOAD_STATUS_FILE, payload);
};
export const bank_ticket_summary = (payload) => {
  return apiInstance.post(BANK_TICKET_SUMMMARY, payload);
};
export const ticket_summary = (payload) => {
  return apiInstance.post(TICKET_SUMMMARY, payload);
};
export const payout_ticket_summary = (payload) => {
  return apiInstance.post(PAYOUT_TICKET_SUMMMARY, payload);
};
export const bank_turnover = (payload) => {
  return apiInstance.post(BANK_TURNOVER, payload);
};
export const merchant_list = (payload) => {
  return apiInstance.post(MERCHANT_LIST, payload);
};
export const fed_transcation = (payload) => {
  return apiInstance.post(FED_TRANSCATION, payload);
};
export const updateFedTransaction = (payload) => {
  return apiInstance.post(UPDATE_FED_TRANSCATION, payload);
};
export const updateIsManualLevel = (payload) => {
  return apiInstance.post(UPDATE_IS_MANUAL_LEVEL, payload);
};
export const get_payout_banklimit = (payload) => {
  return apiInstance.post(GET_PAYOUT_BANKLIMIT, payload);
};
export const get_payment_banklimit = (payload) => {
  return apiInstance.post(GET_PAYMENT_BANKLIMIT, payload);
};
export const get_payment_banklimit_data = (payload) => {
  return apiInstance.post(GET_PAYMENT_BANKLIMIT_DATA, payload);
};
export const update_payment_banklimit = (payload) => {
  return apiInstance.post(UPDATE_PAYMENT_BANKLIMIT, payload);
};
export const update_payout_banklimit = (payload) => {
  return apiInstance.post(UPDATE_PAYOUT_BANKLIMIT, payload);
};
export const active_merchant_PG = (payload) => {
  return apiInstance.post(ACTIVE_MERCHNAT_PG, payload);
};
